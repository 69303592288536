import Vue from "vue";
import VueRouter from "vue-router";
// import Download from "../views/Download.vue";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "Home",
  //   component: Download
  // },
  {
    path: "/",
    name: "Download",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Download.vue")
  }
];

const router = new VueRouter({
  routes
});

export default router;
